import 'reflect-metadata'
import { Expose, Type, Transform, instanceToPlain, type ClassTransformOptions } from 'class-transformer'
import { formatDate } from '@zephyr/dates'

import type {
  IUserRoles,
  IUserCredentials,
  IUser,
  IUserPut,
  IUserPatch,
  IUserPost
} from './interfaces'

import {
  MetaClass,
  BaseToAPIClass,
  type IBaseListData
} from '../../types'

export class UserCredentialsClass implements IUserCredentials {
  @Expose({ name: 'type' })
  public type!: 'phone'

  @Expose({ name: 'value' })
  public value!: string
}

export class UserRolesClass implements IUserRoles {
  @Expose({ name: 'role_id' })
  public roleId!: string

  @Expose({ name: 'role_code' })
  public roleCode!: string

  @Expose({ name: 'role_name' })
  public roleName!: string
}

export class UserClass implements IUser {
  @Expose({ name: 'id' })
  public id!: string

  @Expose({ name: 'tenant_id' })
  public tenantId!: string

  @Expose({ name: 'user_type_code' })
  public userTypeCode!: string

  @Expose({ name: 'user_type_name' })
  public userTypeName!: unknown

  @Expose({ name: 'name' })
  public name!: string

  @Expose({ name: 'credentials' })
  @Type(() => UserCredentialsClass)
  public credentials!: UserCredentialsClass[]

  @Expose({ name: 'assigned_roles' })
  @Type(() => UserRolesClass)
  public assignedRoles!: UserRolesClass[]

  @Expose({ name: 'matched_roles' })
  @Type(() => UserRolesClass)
  public matchedRoles!: UserRolesClass[]

  @Expose({ name: 'meta' })
  public meta!: unknown

  @Expose({ name: 'access_context' })
  public accessContext!: unknown

  @Expose({ name: 'created_at' })
  public createdAt!: string

  @Expose({ name: 'updated_at' })
  public updatedAt!: string

  @Expose({ name: 'locked_at' })
  public lockedAt!: string

}

export class UsersClass implements IBaseListData<UserClass>{
  @Expose({ name: 'items' })
  @Type(() => UserClass)
  public items!: UserClass[]

  @Expose({ name: 'meta' })
  @Type(() => MetaClass)
  public meta!: MetaClass
}

/********************************************************/
/*************** Classes For Send User ******************/
/********************************************************/

export class Credential {
  @Expose({ name: 'type' })
  public type!: string

  @Expose({ name: 'value' })
  public value!: string
}

class BaseUserClass<T> extends BaseToAPIClass<T> implements IUserPut {
  @Expose({ name: 'name' })
  // @Transform(({ value, options }) => {
  //   const locale = options?.locale ?? navigator?.language ?? 'ru'
  //   return { [locale]: value }
  // }, { toPlainOnly: true })
  public name!: string

  @Expose({ name: 'password' })
  public password!: string

  @Expose({ name: 'password_hash' })
  public passwordHash!: string

  @Expose({ name: 'credentials' })
  @Type(() => Credential)
  public credentials!: Credential[]

  @Expose({ name: 'assign_roles' })
  public assignRoles!: string[]

  @Expose({ name: 'locked' })
  public locked!: boolean

  @Expose({ name: 'meta' })
  public meta!: Record<string, string | number>

  @Expose({ name: 'access_context' })
  public accessContext!: Record<string, string | number>
}

export class UserPutClass extends BaseUserClass<IUserPut> {}

export class UserPatchClass extends BaseUserClass<IUserPatch> implements IUserPatch {

  @Expose({ name: 'revoke_roles' })
  public revokeRoles!: string[]
}

export class UserPostClass extends BaseUserClass<IUserPost> implements IUserPost {

  @Expose({ name: 'revoke_roles' })
  public revokeRoles!: string[]

  @Expose({ name: 'id' })
  public id!: string

  @Expose({ name: 'tenant_id' })
  public tenantId!: string

  @Expose({ name: 'user_type_code' })
  public userTypeCode!: string
}