// import './css/pageloader-spinner.css'

export type TLoaderType = 'ball'
  | 'bars'
  | 'classic'
  | 'cspins'
  | 'gyro'
  | 'loading'
  | 'loadingbar'
  | 'pacman'
  | 'points'
  | 'spinner'
  | 'topbar'

export interface IPageLoader {
  show: (type?: TLoaderType, infoText?: string) => void;
  hide: () => void;
  info: (infoText?: string) => void;
}

const pageLoader = () => {
  const ov = document.querySelector('div.page-loader') ?? document.createElement('div')
  ov.className = 'page-loader'
  ov.innerHTML = '<div class="loader-icon"></div>'

  document.getElementsByTagName('body')[0].appendChild(ov)

  const info = document.querySelector('div.page-loader > .info') ?? document.createElement('div')
  info.className = 'info'
  ov.appendChild(info)

  return {
    show: function(type?: TLoaderType, infoText?: string) {
      if (type) {
        import(`./css/pageloader-${type}.css`)
      }

      if (infoText) {
        info.innerHTML = infoText || ''
      } else {
        info.innerHTML = ''
      }

      ov.style.display = 'block'
      setTimeout(function() {
        ov.className = 'page-loader'
      }, 500)
    },
    hide: function() {
      ov.className = 'page-loader hidden'
      setTimeout(function() { ov.style.display = 'none' }, 100)
    },
    info: function(i?: string) {
      info.innerHTML = i || ''
    }
  }
}

const setPageLoaderType = (type: string) => {
  console.log('Pageloader => setType = ', type)
  import(`./css/pageloader-${type}.css`)
}

const setSpinnerColor = (color: string) => {
  document.documentElement.style.setProperty('--pl-spinner-color', color)
}

const setInfoColor = (color: string) => {
  document.documentElement.style.setProperty('--pl-info-color', color)
}

let plBuf: IPageLoader | null = null
/**
 * Создает объект загрузчика страницы с указанным типом загрузчика.
 *
 * @param {TLoaderType} type - Тип загрузчика.
 * @return {IPageLoader} Объект загрузчика страницы.
 */
export const useLoader = (type?: TLoaderType): { loader: IPageLoader } => {
  if (type) {
    setPageLoaderType(type)
  }

  if (plBuf) {
    return {
      loader: plBuf
    }
  } else {
    const pLoader = pageLoader()
    Object.defineProperty(pLoader, 'setType', {
      value: function(type: TLoaderType) {
        setPageLoaderType(type)
      },
      enumerable: false,
      writable: true,
      configurable: true
    })

    Object.defineProperty(pLoader, 'setSpinnerColor', {
      value: function(color: string) {
        setSpinnerColor(color)
      },
      enumerable: false,
      writable: true,
      configurable: true
    })

    Object.defineProperty(pLoader, 'setInfoColor', {
      value: function(color: string) {
        setInfoColor(color)
      },
      enumerable: false,
      writable: true,
      configurable: true
    })

    plBuf = pLoader
    return {
      loader: plBuf
    }
  }
}
