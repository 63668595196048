import {
  ref,
  computed
} from 'vue'

import { useDataFactory } from '@zephyr/framework'

import {
  ProfileClass
} from './classes'

import {
  useEndpoint
} from '@zephyr/framework'

const {
  getMesh,
  getPermissions
} = useEndpoint()

const profile = ref<ProfileClass>(new ProfileClass())

export const useProfile = () => {

  const {
    loading,
    getItem: _getItem,
  } = useDataFactory<ProfileClass>({
    module: 'zep-iam-profile',
    version: 'v1',
    url: '/me/',
    api: getMesh()
  })

  const getProfile = async () => {
    const result = await _getItem()

    profile.value.toClass({
      values: result
    })
  }

  // const enablePermissions = () => {
  //   onUserDataUpdate(() => update())
  // }

  const checkPermission = (permissionName: string) => {
    return getPermissions().checkPermission(permissionName)
  }

  const checkPermissionList = (permissionNames: string[]) => {
    return getPermissions().checkPermissionList(permissionNames)
  }

  return {
    loading: computed(() => loading.value),
    profile: computed(() => profile.value),
    getProfile,
    // enablePermissions,
    checkPermission,
    checkPermissionList
  }
}