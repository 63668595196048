import { createRouter, createWebHistory } from 'vue-router'

import qs from 'qs'
import { useEndpoint, IPortalRouter, useWorkspace } from '@zephyr/framework'
import { createRoutes } from './routes'
import { useProfile } from '@/composable/use-profile/useProfile'

const stringify = (str) => {
  return qs.stringify(str, { encode: false })
}

declare module 'vue-router' {
  interface RouteMeta {
    title?: string;
  }
}

export const initRouter = async (portalRoutes: IPortalRouter[]) => {
  const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
      if (to.hash) {
        return new Promise((resolve, _reject) => {
          setTimeout(() => {
            resolve({ el: to.hash })
          }, 500)
        })
      }
      if (savedPosition) {
        return savedPosition
      }
      if (to.meta.noScroll && from.meta.noScroll) {
        return {}
      }
      return { top: 0 }
    },
    routes: await createRoutes(portalRoutes),

    parseQuery: qs.parse,
    stringifyQuery: stringify
  })

  router.beforeEach(async (to) => {
    const { getAuthFlow } = useEndpoint()
    const { checkLoggedIn } = getAuthFlow()
    const isLoggedIn = await checkLoggedIn()

    if (isLoggedIn) {
      const { getProfile, profile } = useProfile()
      if (!profile.value.id && to.name !== 'login') {
        await getProfile()
      }
    }
    
    return true
  })

  return router
}