import 'reflect-metadata'
import { Exclude, Expose, Type } from 'class-transformer'

import {
  IProfile
} from './interfaces'
import {
  BaseToAPIClass
} from '../types'
import { Credential, UserRolesClass } from '../use-iam/useUsers/classes'

export class ProfileClass extends BaseToAPIClass<IProfile> implements IProfile {
  @Expose({ name: 'id' })
    id!: string

  @Expose({ name: 'tenant_id' })
    tenantId!: string

  @Expose({ name: 'user_type_code' })
    userTypeCode!: string

  @Expose({ name: 'user_type_name' })
    userTypeName!: string

  @Expose({ name: 'name' })
    name!: string

  @Expose({ name: 'timezone' })
    timezone!: string

  @Expose({ name: 'locale_iso2_code' })
    localeIso2Code!: string

  @Expose({ name: 'credentials' })
  @Type(() => Credential)
    credentials!: Credential[]
  
  @Expose({ name: 'assigned_roles' })
  @Type(() => UserRolesClass)
    assignedRoles!: UserRolesClass[]

  @Expose({ name: 'matched_roles' })
  @Type(() => UserRolesClass)
    matchedRoles!: UserRolesClass[]

  @Exclude({ toPlainOnly: true })
  get emails() {
    return this.credentials?.filter(c => c.type === 'email')?.map(c => c.value)
  }
  
  @Exclude({ toPlainOnly: true })
  get phones() {
    return this.credentials?.filter(c => c.type === 'phone')?.map(c => c.value)
  }
}