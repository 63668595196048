
import { useProfile } from '@/composable/use-profile/useProfile'
import { loader } from '@/loader'
import { useWorkspace , useEndpoint, useRouterGenerator, useI18nService, IPortalRouter } from '@zephyr/framework'

export const createRoutes = async (portalRoutes: IPortalRouter[]) => {
  const { generateRoutes } = useRouterGenerator()
  const i18n = useI18nService()
  const additionalRoutes: IPortalRouter[] = [
    ...(portalRoutes ?? [])
  ]

  const loadedRoutes = await generateRoutes(additionalRoutes)

  const routes = [
    {
      path: '/',
      name: 'root',
      component: () => import('@/layouts/MainLayout/MainLayout.vue'),
      children: [
        {
          path: '',
          name: 'home',
          props: true,
          component: () => import('@/pages/Section.vue'),
          children: [
            {
              path: '',
              name: 'dashboard',
              meta: {
                title: i18n.t('dashboard.title'),
              },
              component: () => import('@/pages/Dashboard/Dashboard.vue')
            },
          ]
        },
        {
          path: 'profile',
          name: 'profile',
          redirect: {
            name: 'profile-view'
          },
          meta: {
            title: 'Профиль',
            options: {
              menuCode: 'profile',
            }
          },
          component: () => import('@/pages/Section.vue'),
          children: [
            {
              path: 'view',
              name: 'profile-view',
              meta: {
                title: 'Просмотр'
              },
              component: () => import('@/pages/Profile/Profile.vue')
            }
          ]
        },
      ],
      beforeEnter: async (to: any) => {
        const { getAuthFlow, getPermissions } = useEndpoint()
        const { checkLoggedIn } = getAuthFlow()
        
        const isLoggedIn = await checkLoggedIn()
        
        console.log('beforeEnter router hook')
        
        if (isLoggedIn) {
          if (!getPermissions().checkPermissionList(to?.meta?.permissions)) {
            return { name: 'no-access' }
          }

          loader.hide()
          console.log(`Route to ${to.name} is ${isLoggedIn ? 'allowed' : 'denied'}`)

          return true
        } else {
          return {
            name: 'login'
          }
        }
      }
    },
    {
      path: '/auth',
      component: () => import('@/layouts/AuthLayout/AuthLayout.vue'),
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('@/pages/IAM/Login/Login.vue')
        }
      ]
    },
    {
      path: '/no-access',
      name: 'no-access',
      component: () => import('@/pages/403/index.vue')
    },
    {
      path: '/:catchAll(.*)*',
      name: 'not-found',
      component: () => import('@/pages/404/index.vue')
    }
  ]

  if (loadedRoutes?.length) {
    const rootRoute = routes.find(item => item.name === 'root')
    if (rootRoute && !rootRoute?.children?.length) {
      rootRoute.children = []
    }
    rootRoute?.children.push(...loadedRoutes)
  }

  console.log('Create routes => ', routes)

  return routes
}
