import { 
  useLoader
} from '@/composable/use-loader/useLoader'

const { 
  loader
} = useLoader()
loader.show()
// loader.info('Загрузка ресурсов')

export {
  loader
}
