import 'reflect-metadata'
import { Expose, instanceToPlain, plainToInstance, type ClassTransformOptions } from 'class-transformer'

export interface IDataResponse<T> {
  success: boolean
  data?: T
  error?: unknown
  response?: unknown
}

export interface IMeta {
  page?: number
  pageSize?: number
  totalCount?: number
  totalPages?: number
}

export interface IBaseListData<T = unknown> {
  items: T[]
  meta: IMeta
}

export class MetaClass implements IMeta {
  @Expose({ name: 'page' })
    page?: number | undefined

  @Expose({ name: 'page_size' })
    pageSize?: number | undefined
  
  @Expose({ name: 'total_pages' })
    totalPages?: number

  @Expose({ name: 'total_count' })
    totalCount?: number
}

export {
  instanceToPlain
}

export class BaseToAPIClass<C> {
  toAPI(locale: string, options?: ClassTransformOptions) {
    return instanceToPlain(this, {
      enableImplicitConversion: true,
      ...{
        ...options,
        locale: locale ?? navigator?.language ?? 'en',
      }
    })
  }

  toClass({ values, options, locale }: { values: Partial<C>, options?: ClassTransformOptions, locale?: string }) {
    const newValues = plainToInstance(this.constructor as new () => C, values, {
      enableImplicitConversion: true,
      ...{
        ...options,
        locale: locale ?? navigator?.language ?? 'en',
      }
    })

    this.setValues(newValues)
  }

  setValues(values: Partial<C>): void {
    for (const key in values) {
      if (key in values && key in this) {
        this[key as keyof this] = values[key] as this[keyof this]
      }
    }
  }
}